import React, { useState, useEffect } from 'react';

function Title() {
    const [titleText, setTitleText] = useState('');
    const [index, setIndex] = useState(0);

    const text = 'contract.red';  // Define text here

    useEffect(() => {
        const intervalId = setInterval(typeText, 100);  // Set interval for typing effect
        return () => clearInterval(intervalId);  // Clear interval on component unmount
    }, [index]);

    const getRandomChar = () => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        return chars.charAt(Math.floor(Math.random() * chars.length));
    };

    const getScrambledString = (length) => {
        let scrambled = '';
        for (let i = 0; i < length; i++) {
            scrambled += getRandomChar();
        }
        return scrambled;
    };

    const typeText = () => {
        if (index < text.length) {
            setTitleText(text.substring(0, index + 1) + getScrambledString(text.length - index - 1));
            setIndex(prevIndex => prevIndex + 1);
        } else {
            setTitleText(text);  // Set the final text once unscrambling is complete
        }
    };

    return (
        <h1 id="title">
            <span dangerouslySetInnerHTML={{ __html: titleText.replace('red', '<span style="color:#ff0000;">red</span>') }}></span>
        </h1>
    );
}

export default Title;
