/* global particlesJS */
import React from 'react';
import 'particles.js/particles.js';
import Title from './Title';
import SubscriptionForm from './SubscriptionForm';
import './styles.css';
import './SubscribeButton.css';  // Ensure this path is correct
import musicTrack from './audio/music.mp3';


const SoundWaves = ({ isMuted, onToggleAudio }) => (
    <div
        id="sound-waves-container"
        className={isMuted ? 'muted' : ''}
        onClick={onToggleAudio}  // Add this line
    >
        {Array.from({ length: 5 }).map((_, index) => (
            <div
                key={index}
                className="sound-wave"
                style={{
                    top: `${index * 10}px`, /* Space the waves apart */
                    animationDelay: `${index * 0.5}s`, /* Stagger start times */
                }}
            />
        ))}
    </div>
);


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAudioMuted: false,
            isLoggedIn: false, // Add this line
        };
        this.audioRef = React.createRef();  // Create a ref to the audio element
        this.handleToggleAudio = this.handleToggleAudio.bind(this);
        this.handleLogin = this.handleLogin.bind(this); // Add this line
    }
    handleLogin() {
        this.setState({ isLoggedIn: true });
    }
    componentDidMount() {
        particlesJS('particles-js', {
            particles: {
                number: {
                    value: 100,
                    density: { enable: true, value_area: 800 }
                },
                color: { value: '#ff0000' },
                shape: { type: 'circle', polygon: { nb_sides: 5 } },
                size: { value: 3, random: true, anim: { enable: true, speed: 40, size_min: 0.1, sync: false } },
                line_linked: { enable: false, color: '#ff0000', opacity: 1, width: 2 },
                move: {
                    enable: true,
                    speed: 3,
                    direction: 'top-right',
                    random: false,
                    straight: true,
                    out_mode: 'out',
                    bounce: false,
                    attract: { enable: false, rotateX: 600, rotateY: 1200 }
                }
            },
            interactivity: {
                detect_on: 'canvas',
                events: {
                    onhover: { enable: true, mode: 'grab' },
                    onclick: { enable: true, mode: 'repulse' },
                    resize: true
                },
                modes: {
                    grab: { distance: 100, line_linked: { opacity: 2 } },
                    bubble: { distance: 100, size: 20, duration: 2, opacity: 8, speed: 3 },
                    repulse: { distance: 100, duration: 0.5 },
                    push: { particles_nb: 55 },
                    remove: { particles_nb: 2 }
                }
            },
            retina_detect: true
        });
    }

    handleToggleAudio() {
        // Toggle the audio muted state
        this.setState(prevState => ({
            isAudioMuted: !prevState.isAudioMuted
        }), () => {
            this.audioRef.current.muted = this.state.isAudioMuted;  // Update the audio element's muted property
        });
    }


    render() {


        return (
            <div id="container">
                <div id="particles-js"></div>

                <div id="content">

                    <Title />
                    <SubscriptionForm />
                    {/* Render the audio element and sound waves */}
                    <audio ref={this.audioRef} src={musicTrack} autoPlay loop />
                    <SoundWaves
                        isMuted={this.state.isAudioMuted}
                        onToggleAudio={this.handleToggleAudio}  // Pass the handler as a prop
                    />

                </div>
            </div>
        );
    }

}  // This closing brace was missing

export default App;
