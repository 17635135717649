// Import the necessary libraries and components
import React from 'react';
import JSConfetti from 'js-confetti';

class SubscriptionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            isInputFocused: false,
            isLoading: false,  // Add this line to initialize the loading state
        };
        this.jsConfetti = new JSConfetti();  // Create a new instance of JSConfetti
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputFocus = this.handleInputFocus.bind(this);  // Add this line
        this.handleInputBlur = this.handleInputBlur.bind(this);  // Add this line
        this.handleInputChange = this.handleInputChange.bind(this);  // Add this line
    }

    // In your SubscriptionForm.js
    async handleSubmit(event) {
        event.preventDefault();

        this.setState({ isLoading: true });  // Set the loading state to true when the form is submitted

        const { email } = this.state;

        try {
            const response = await fetch('https://contractadmin-88a29345a542.herokuapp.com/submit-email/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }


            this.setState({
                submitted: true,
                submissionMessage: 'Submitted! Thank you for subscribing.',
            });

            this.jsConfetti.addConfetti({
                confettiNumber: 500,
                confettiColors: ['#ff0a54', '#ff477e', '#ff7096', '#ff85a1', '#fbb1bd', '#f9bec7'],
                emojiSize: 100,
                confettiRadius: 4,
                gravity: 0.4,
                origin: { y: 0.1, x: 0.5 },
            });
            this.setState({ isLoading: false });  // Set the loading state back to false when the request is complete

        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
        }
    };

    handleInputChange(event) {
        this.setState({
            email: event.target.value,
        });
    }

    handleInputFocus() {  // New handler
        this.setState({
            isInputFocused: true,
        });
    };

    handleInputBlur() {  // New handler
        this.setState({
            isInputFocused: false,
        });
    };

    render() {
        return (
            <div id="email-subscription">
                <form id="subscribe-form" onSubmit={this.handleSubmit}>
                    <div className="email-box">
                        <div className="input-wrapper">
                            <input
                                id="email-input"
                                type="email"
                                value={this.state.email}
                                onChange={this.handleInputChange}  // Updated prop
                                onFocus={this.handleInputFocus}  // New prop
                                onBlur={this.handleInputBlur}  // New prop
                                required
                            />
                            <label
                                htmlFor='email-input'
                                className='placeholder'>
                                {this.state.isInputFocused ? 'Enter your email' : 'Join the waitlist'}  {/* Conditional rendering */}
                            </label>
                        </div>
                        <div className="cont">
                            <button id="subscribe-button" type="submit" className={this.state.submitted ? 'submitted' : ''} disabled={this.state.isLoading}>
                                <span>{this.state.isLoading ? 'Submitting...' : 'Subscribe'}</span>
                                <img src="https://i.cloudup.com/2ZAX3hVsBE-3000x3000.png" alt="Checkmark" />
                            </button>
                        </div>
                    </div>
                </form>
                {this.state.submissionMessage && (
                    <p id="submission-message">{this.state.submissionMessage}</p>
                )}
            </div>
        );
    }
}

export default SubscriptionForm;
